import styled from 'styled-components';

import { query } from '@shared/style-helpers';

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const TableWrapper = styled.div`
  min-width: 1450px;
  margin-right: 24px;
  max-height: ${({ expanded }) => (expanded ? '4224px' : '576px')};
  transition: max-height 1s ease-in-out;
  overflow-y: hidden;

  @media ${query.minDesktop} {
    margin-right: 40px;
  }
`;

export const CellContentWrapper = styled.div`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;

  cursor: ${({ onClick }) => onClick && 'pointer'};
`;

export const FlagIcon = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.28);

  background-image: ${({ backgroundImage }) => backgroundImage};
`;
