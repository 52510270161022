import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import { ImageCore, Table, Tooltip } from '@components';

import ascendingSortIcon from '@images/adoption-index/ascending-sort.svg';
import descendingSortIcon from '@images/adoption-index/descending-sort.svg';
import defaultSortIcon from '@images/adoption-index/no-sort.svg';
import infoIcon from '@images/adoption-index/info-icon.svg';
import { ConditionalWrapper, sortDataByKey } from '@shared/helpers';
import {
  ADOPTION_TABLE_DATA,
  YEAR_PERIOD_2020,
  YEAR_PERIOD_2021,
  YEAR_PERIOD_2022,
  YEAR_PERIOD_2023,
} from '@shared/constants/pages/adoption-index';
import { useI18nCountries } from '@hooks';

import { CellContentWrapper, FlagIcon, TableContainer, TableWrapper } from './wrapper-components';
import { formatNumber } from './helpers';

const MISSING_DATA_PLACEHOLDER = 'N/A';

const headers = (labels) => [
  { name: 'position', label: labels.position, rowSpan: 2 },
  { name: 'country', label: labels.country, rowSpan: 2 },
  { name: 'downloads', label: labels.downloads, colSpan: 4 },
  { name: 'population', label: labels.population, rowSpan: 2 },
  { name: 'adoption', label: labels.vpnAdoptionIndex, colSpan: 4 },
];

const subHeaders = [
  { name: 'downloads_primary', label: YEAR_PERIOD_2023 },
  { name: 'downloads_secondary', label: YEAR_PERIOD_2022 },
  { name: 'downloads_tertiary', label: YEAR_PERIOD_2021 },
  { name: 'downloads_quaternary', label: YEAR_PERIOD_2020 },
  { name: 'adoption_primary', label: YEAR_PERIOD_2023 },
  { name: 'adoption_secondary', label: YEAR_PERIOD_2022 },
  { name: 'adoption_tertiary', label: YEAR_PERIOD_2021 },
  { name: 'adoption_quaternary', label: YEAR_PERIOD_2020 },
];

const contentCellNames = [
  'position',
  'country',
  'downloads_primary',
  'downloads_secondary',
  'downloads_tertiary',
  'downloads_quaternary',
  'population',
  'adoption_primary',
  'adoption_secondary',
  'adoption_tertiary',
  'adoption_quaternary',
];

const getCellContent = (key, rowData, position, language, i18nCountries) => {
  switch (key) {
    case 'position':
      return position;
    case 'country':
      return (
        <CellContentWrapper>
          <FlagIcon
            backgroundImage={`url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${rowData.iso.toLowerCase()}.svg)`}
          />
          {i18nCountries.getName(rowData.iso, language, { select: 'alias' })}
        </CellContentWrapper>
      );
    case 'downloads_primary':
      return (
        rowData.downloads_primary?.toLocaleString().replace(/,/g, ' ') || MISSING_DATA_PLACEHOLDER
      );
    case 'downloads_secondary':
      return (
        rowData.downloads_secondary?.toLocaleString().replace(/,/g, ' ') || MISSING_DATA_PLACEHOLDER
      );
    case 'downloads_tertiary':
      return (
        rowData.downloads_tertiary?.toLocaleString().replace(/,/g, ' ') || MISSING_DATA_PLACEHOLDER
      );
    case 'downloads_quaternary':
      return (
        rowData.downloads_quaternary?.toLocaleString().replace(/,/g, ' ') ||
        MISSING_DATA_PLACEHOLDER
      );
    case 'population':
      return formatNumber(rowData.population);
    case 'adoption_primary':
      return rowData.adoption_primary ? `${rowData.adoption_primary}%` : MISSING_DATA_PLACEHOLDER;
    case 'adoption_secondary':
      return rowData.adoption_secondary
        ? `${rowData.adoption_secondary}%`
        : MISSING_DATA_PLACEHOLDER;
    case 'adoption_tertiary':
      return rowData.adoption_tertiary ? `${rowData.adoption_tertiary}%` : MISSING_DATA_PLACEHOLDER;
    case 'adoption_quaternary':
      return rowData.adoption_quaternary
        ? `${rowData.adoption_quaternary}%`
        : MISSING_DATA_PLACEHOLDER;
    default:
      return null;
  }
};

const sortProperties = {
  downloads_primary: 'downloads_primary',
  downloads_secondary: 'downloads_secondary',
  downloads_tertiary: 'downloads_tertiary',
  downloads_quaternary: 'downloads_quaternary',
  population: 'population',
  adoption_primary: 'adoption_primary',
  adoption_secondary: 'adoption_secondary',
  adoption_tertiary: 'adoption_tertiary',
  adoption_quaternary: 'adoption_quaternary',
};

const CountryTable = ({ countryData, infoText }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { i18nCountries } = useI18nCountries(language);

  const [expanded, setExpanded] = useState(false);
  const [sortToggle, setSortToggle] = useState(true);
  const [sortKey, setSortKey] = useState('adoption_primary');
  const [sortableData, setSortableData] = useState([...countryData]);
  const buttonName = expanded ? t('common:actions.loadLess') : t('common:actions.loadMore');

  const handleSortClick = (key) => {
    const data = [...sortableData];
    let isReversed = sortToggle;
    sortKey === key && isReversed ? (isReversed = false) : (isReversed = true);
    setSortKey(key);
    setSortableData(sortDataByKey(data, key, isReversed));
    setSortToggle(isReversed);
  };

  const isHighlighted = (key) => key.split('_')[0] === 'adoption';

  const getSortIcon = (key) => {
    let imageIcon;
    if (sortKey === key) {
      if (sortToggle) {
        imageIcon = descendingSortIcon;
      } else {
        imageIcon = ascendingSortIcon;
      }
    } else {
      imageIcon = defaultSortIcon;
    }
    return imageIcon;
  };

  const renderHeaderCell = (item, isFirstRow) => {
    const isSortable = !!sortProperties[item.name];
    const hasTooltip = item.name === 'adoption';

    return (
      <Table.HeaderCell
        rowSpan={item.rowSpan}
        colSpan={item.colSpan}
        isFirstRow={isFirstRow}
        textAlign={item.name === 'country' ? 'left' : 'center'}
        isHighlighted={isHighlighted(item.name)}
        key={item.name}
        spacing={{ px: 25 }}
      >
        <ConditionalWrapper
          condition={hasTooltip}
          wrapper={(children) => <Tooltip content={infoText}>{children}</Tooltip>}
        >
          <CellContentWrapper
            onClick={isSortable ? () => handleSortClick(sortProperties[item.name]) : undefined}
          >
            {item.label}
            {isSortable && (
              <ImageCore
                src={getSortIcon(sortProperties[item.name])}
                alt={`sort ${sortProperties[item.name]}`}
                ml={10}
              />
            )}
            {hasTooltip && (
              <ImageCore src={infoIcon} alt="Info icon" position="relative" right={-5} top={-5} />
            )}
          </CellContentWrapper>
        </ConditionalWrapper>
      </Table.HeaderCell>
    );
  };

  const renderHeaderRow = (items, isFirstRow) => {
    if (items?.length) {
      return <Table.Row>{items.map((item) => renderHeaderCell(item, isFirstRow))}</Table.Row>;
    }
  };

  const { labels } = ADOPTION_TABLE_DATA(t);

  return (
    <React.Fragment>
      <TableContainer>
        <TableWrapper expanded={expanded}>
          <Table>
            <Table.Head>
              {renderHeaderRow(headers(labels), true)}
              {renderHeaderRow(subHeaders)}
            </Table.Head>
            <Table.Body>
              {sortableData.map((rowData, i) => {
                const isOddRow = !(i % 2);

                return (
                  <Table.Row key={i} isOddRow={isOddRow}>
                    {contentCellNames.map((name) => (
                      <Table.Cell
                        textAlign={name === 'country' ? 'left' : 'center'}
                        isHighlighted={isHighlighted(name)}
                        isOddRow={isOddRow}
                        key={name}
                        spacing={{ px: 25 }}
                      >
                        <CellContentWrapper>
                          {i18nCountries &&
                            getCellContent(name, rowData, i + 1, language, i18nCountries)}
                        </CellContentWrapper>
                      </Table.Cell>
                    ))}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </TableWrapper>
      </TableContainer>
      <Box mx="auto" pt={40}>
        <Button type="button" size="large" width={280} onClick={() => setExpanded(!expanded)}>
          {buttonName}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default CountryTable;
