import React from 'react';

import { SectionContainerSC as SectionContainer } from '@components';
import { Box } from '@core';

import { CountryTable } from './components';

const CountryTableSection = ({ countryData, tableTitle, tableText, infoText }) => (
  <Box backgroundColor="#fafafa">
    <SectionContainer
      title={tableTitle}
      titleProps={{ fontFamily: 'default' }}
      description={tableText}
      wrapperProps={{ pt: 64, pb: 0 }}
    ></SectionContainer>
    <Box
      maxWidth={1692}
      display="flex"
      flexDirection="column"
      mx="auto"
      pb={64}
      pl={{ _: 24, lg: 40 }}
    >
      <CountryTable countryData={countryData} infoText={infoText} />
    </Box>
  </Box>
);

export default CountryTableSection;
